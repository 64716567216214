import dayjs from "dayjs";

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const debounce = (func, delay) => {
    let timeoutId;

    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func.apply(null, args);
        }, delay);
    };
};

export function numberWithCommas(x) {
    if(!x){ return 0};
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
}

export function setCookie(name, value, expires_in) {
    document.cookie = `${name}=${value}; max-age=${expires_in}; path=/`;
}

export function deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getCookie(name) {
    let cookieArray = document.cookie.split(';');
    for (let cookie of cookieArray) {
        let [cookieName, cookieValue] = cookie.split('=');
        cookieName = cookieName.trim();
        if (cookieName === name) {
            return cookieValue;
        }
    }
    return null;
}

export function getInitials(fullName) {
    // Kiểm tra nếu fullName không phải là chuỗi hoặc là chuỗi rỗng
    if (typeof fullName !== 'string' || fullName.trim().length === 0) {
        return 'MV'; // Trả về chuỗi rỗng nếu không hợp lệ
    }

    // Loại bỏ khoảng trắng thừa và tách chuỗi thành các từ
    const words = fullName.trim().split(/\s+/);

    // Nếu có nhiều từ, lấy ký tự đầu tiên của từ đầu tiên và từ thứ hai
    if (words.length > 1) {
        const firstInitial = words[0][0].toUpperCase();
        const secondInitial = words[1][0].toUpperCase();
        return firstInitial + secondInitial;
    }

    // Nếu chỉ có một từ, lấy hai chữ cái đầu tiên của từ đó
    return words[0].slice(0, 2).toUpperCase();
}

export const getStatusLabelAndColorClass = (endDate) => {
    const today = dayjs().startOf('day');
    const end = dayjs(endDate, "YYYY-MM-DD");
    const daysLeft = end.diff(today, 'day');
    
    let label = '';
    let colorClass = ''; // Mặc định màu xám
    
    if (daysLeft < 0) {
        label = 'Expired';
        colorClass = 'text-xs ml-1 block text-red-500'; // Màu đỏ cho đã hết hạn
    } else if (daysLeft == 1) {
        label = '1 day left';
        colorClass = 'text-xs ml-1 block text-orange-600'; // Màu cam cho 1 ngày còn lại
    } else if (daysLeft == 2) {
        label = '2 days left';
        colorClass = 'text-xs ml-1 block text-orange-500'; // Màu cam cho 2 ngày còn lại
    } else if (daysLeft == 3) {
        label = '3 days left';
        colorClass = 'text-xs ml-1 block text-orange-400'; // Màu cam cho 3 ngày còn lại
    } 
    
    return { label, colorClass };
};